import {ApplicationRef, Injectable, ViewContainerRef} from '@angular/core';
import {Observable} from 'rxjs';
import {AcceptanceLeaveComponentModal} from "../../shared/modals/acceptance-leave-component-modal/acceptance-leave-component-modal.component";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard {
  viewContainerRef: ViewContainerRef;

  constructor(private appRef: ApplicationRef) {
    this.viewContainerRef = this.appRef.components[0].instance.vcr;
  }

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    if (component.canDeactivate && !component.canDeactivate()) {
      const acceptanceComponent = this.viewContainerRef.createComponent(AcceptanceLeaveComponentModal);
      return acceptanceComponent.instance.confirmed$;
    } else {
      return true;
    }
  }
}
