<p-dialog  [(visible)]="visible" appendTo="body" [modal]="true" [breakpoints]="{ '960px': '75vw', '640px': '100vw' }" [style]="{ 'max-height': '90vh', 'overflow': 'auto', width: '40vw' }" [closable]="false">
  <ng-template pTemplate="header">
    <div class="flex align-items-center">
      <span class="flex align-items-center justify-content-center text-orange-500 mr-3 border-circle" style="width: 32px; height: 32px">
        <i class="pi pi-question-circle text-3xl"></i>
      </span>
      <span class="font-medium text-2xl text-900">
        <span>{{ 'label.modal.leaveComponent.title' | transloco: { default: 'Pagina verlaten?' } }}</span>
      </span>
    </div>
  </ng-template>
  <p class="line-height-3 p-0 m-0">
    <span>{{ 'label.modal.leaveComponent.message' | transloco: { default: 'Als u de pagina verlaat zonder op te slaan, gaan al uw wijzigingen verloren. Bent u zeker dat u de pagina wenst te verlaten?' } }}</span>
  </p>
  <ng-template pTemplate="footer">
    <div class="border-top-1 surface-border pt-3">
      <button pButton pRipple (click)="deny()" class="p-button-sm p-button-secondary p-button-text" label="{{ 'label.modal.leaveComponent.deny' | transloco: { default: 'Annuleren' } }}"></button>
      <button pButton pRipple (click)="confirm()"  class="p-button-sm p-button-warning" icon="pi pi-sign-out" label="{{ 'label.modal.leaveComponent.confirm' | transloco: { default: 'Verlaten' } }}"></button>
    </div>
  </ng-template>
</p-dialog>
